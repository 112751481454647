import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
import { Box, Flex } from "@catchoftheday/cg-components";
import { PageLayoutZone } from "../../layouts/PageLayout/PageLayout.jsx";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var DepartmentPageLayoutZone = function DepartmentPageLayoutZone(props) {
  return ___EmotionJSX(PageLayoutZone, _extends({}, props, {
    rowGap: 8,
    py: 4
  }));
};
export var TopBrandsZone = function TopBrandsZone(props) {
  return ___EmotionJSX(Flex, _extends({
    display: "grid",
    gridTemplateColumns: {
      xs: "repeat(4, minmax(0, 1fr))",
      md: "repeat(8, minmax(0, 1fr))"
    },
    gridTemplateRows: {
      xs: "repeat(2, auto)",
      md: "1fr"
    },
    alignItems: "center",
    gap: {
      xs: 8,
      md: 12
    },
    my: {
      xs: 8,
      md: 12
    }
  }, props));
};
export var SectionZone = function SectionZone(props) {
  return ___EmotionJSX(Box, _extends({
    mt: 3
  }, props));
};
export var RecommenderZone = function RecommenderZone(props) {
  return ___EmotionJSX(Box, _extends({
    overflow: "hidden",
    mx: {
      xs: "-12px",
      md: "auto"
    }
  }, props));
};