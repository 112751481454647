import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import React from "react";
import { Box, Heading, Text } from "@catchoftheday/cg-components";
import { styled } from "@catchoftheday/theme";
import { jsx as ___EmotionJSX } from "@emotion/react";
var DescriptionText = styled(Text)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  > p {\n    margin: 0;\n  }\n"])));
export var SeoDescription = function SeoDescription(_ref) {
  var departmentName = _ref.departmentName,
      description = _ref.description;
  return ___EmotionJSX(Box, {
    display: "grid",
    rowGap: 2
  }, ___EmotionJSX(Heading.h4, {
    m: 0
  }, departmentName), ___EmotionJSX(DescriptionText, {
    dangerouslySetInnerHTML: {
      __html: description
    },
    m: 0,
    fontSize: "sm"
  }));
};