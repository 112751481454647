import { graphql } from "msw";
import { MOCK_HEADER_DATA } from "@header/mocks/data";
export var headerHandlers = [graphql.query("HeaderConfiguration", function (_request, response, ctx) {
  return response(ctx.data({
    cmsConfiguration: {
      header: MOCK_HEADER_DATA
    }
  }));
}), graphql.query("SearchRedirects", function (_request, response, ctx) {
  return response(ctx.data({
    searchRedirects: {
      redirects: [{
        searchTerm: "catchconnect",
        targetPath: "/catch-connect"
      }, {
        searchTerm: "flybuys",
        targetPath: "/flybuys-catch"
      }]
    }
  }));
})];