import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["loading", "breadcrumbs"];
import React from "react";
import { Box, Breadcrumbs } from "@catchoftheday/cg-components";
import { CategoryBreadcrumbsPlaceholder } from "./CategoryBreadcrumbsPlaceholder";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var CategoryBreadcrumbs = function CategoryBreadcrumbs(_ref) {
  var loading = _ref.loading,
      breadcrumbs = _ref.breadcrumbs,
      containerProps = _objectWithoutProperties(_ref, _excluded);

  if (loading) {
    return ___EmotionJSX(CategoryBreadcrumbsPlaceholder, null);
  }

  if (!breadcrumbs.length) {
    return null;
  }

  var mappedBreadcrumbs = breadcrumbs.filter(function (_ref2) {
    var url = _ref2.url;
    return !!url;
  }).map(function (category, idx) {
    return {
      label: category.name,
      href: category.url,
      leaf: idx === breadcrumbs.length - 1
    };
  });
  return ___EmotionJSX(Box, containerProps, ___EmotionJSX(Breadcrumbs, {
    breadcrumbs: mappedBreadcrumbs,
    useNextLinks: true,
    listProps: {
      mb: 0
    }
  }));
};