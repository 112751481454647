import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["events"];
import React from "react";
import { SOURCE_TYPE_DEPARTMENT_EVENT } from "@catchoftheday/analytics";
import { Box, EventCard, Flex, Heading } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
var sourceType = SOURCE_TYPE_DEPARTMENT_EVENT;
export var EventGrid = function EventGrid(_ref) {
  var events = _ref.events,
      containerProps = _objectWithoutProperties(_ref, _excluded);

  return ___EmotionJSX(Box, null, ___EmotionJSX(Heading.h2, {
    mt: 0,
    mb: 4
  }, "Don't miss out on these deals"), ___EmotionJSX(Flex, _extends({
    display: "grid",
    gridTemplateColumns: {
      xs: "repeat(1, 1fr)",
      md: "repeat(2, 1fr)"
    },
    gap: 4
  }, containerProps), events.map(function (event) {
    return ___EmotionJSX(EventCard, {
      key: event.id,
      title: event.title,
      url: "".concat(event.url, "?st=").concat(sourceType),
      image: {
        url: event.image.single.url,
        altText: event.image.single.altText
      },
      badgeText: event.badgeText
    });
  })));
};