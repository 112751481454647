import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["idPath"];
import React, { useContext, useMemo, useRef } from "react";
import { Box, List } from "@catchoftheday/cg-components";
import { MappedMenuItems } from "../../../../consts";
import { MenuItem } from "../../../MenuItem";
import { MobileMenuContext } from "../../../MobileMenuContext";
import { getSubCategoriesMenu } from "../SubCategoriesMenu";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var LinksMenu = function LinksMenu(_ref) {
  var idPath = _ref.idPath,
      props = _objectWithoutProperties(_ref, _excluded);

  var _useContext = useContext(MobileMenuContext),
      _useContext$items = _useContext.items,
      items = _useContext$items === void 0 ? [] : _useContext$items;

  var activeCategoryPosition = useRef(0);
  var activeSubCategoryPosition = useRef(0);
  var activeCategory = useMemo(function () {
    return items.find(function (_ref2, index) {
      var id = _ref2.id;

      if (id === idPath[0]) {
        activeCategoryPosition.current = index + 1;
        return true;
      }

      return false;
    });
  }, [items, idPath]);
  var activeSubCategory = useMemo(function () {
    return (getSubCategoriesMenu(activeCategory) || []).find(function (_ref3, index) {
      var id = _ref3.id;

      if (id === idPath[1]) {
        activeSubCategoryPosition.current = index + 1;
        return true;
      }

      return false;
    });
  }, [idPath, activeCategory]);
  var linksMenu = useMemo(function () {
    var _activeSubCategory$to, _activeSubCategory$ch;

    if (!activeSubCategory) {
      return null;
    }

    if (activeSubCategory.id === MappedMenuItems.SHOP_BY_BRAND_ID && !!((_activeSubCategory$to = activeSubCategory.topBrands) !== null && _activeSubCategory$to !== void 0 && _activeSubCategory$to.length)) {
      return activeSubCategory.topBrands;
    }

    return (_activeSubCategory$ch = activeSubCategory.children) !== null && _activeSubCategory$ch !== void 0 && _activeSubCategory$ch.length ? activeSubCategory.children : null;
  }, [activeSubCategory]);

  if (!linksMenu || !activeCategory || !activeSubCategory) {
    return null;
  }

  var isShopByBrand = activeSubCategory.id === MappedMenuItems.SHOP_BY_BRAND_ID;
  return ___EmotionJSX(Box, _extends({
    as: "nav",
    overflowY: "scroll"
  }, props), ___EmotionJSX(List, {
    my: "0px"
  }, !isShopByBrand && ___EmotionJSX(MenuItem, {
    key: "Shop All ".concat(activeSubCategory.name, "-0"),
    url: activeSubCategory.url,
    hasChildren: false,
    itemPath: [{
      name: activeCategory.name,
      id: activeCategory.id,
      position: activeCategoryPosition.current
    }, {
      name: activeSubCategory.name,
      id: activeSubCategory.id,
      position: activeSubCategoryPosition.current
    }, {
      name: "Shop All ".concat(activeSubCategory.name),
      id: activeSubCategory.id,
      position: 1
    }],
    shopAll: true
  }, "Shop All ".concat(activeSubCategory.name)), linksMenu.map(function (menuItem, index) {
    return ___EmotionJSX(MenuItem, {
      key: "".concat(menuItem.name, "-").concat(index + 1),
      url: menuItem.url,
      hasChildren: false,
      itemPath: [{
        name: activeCategory.name,
        id: activeCategory.id,
        position: activeCategoryPosition.current
      }, {
        name: activeSubCategory.name,
        id: isShopByBrand ? activeCategory.id : activeSubCategory.id,
        position: activeSubCategoryPosition.current
      }, {
        name: menuItem.name,
        id: menuItem.id,
        // Start from 1 and account for the Shop All link
        // If level 2 is Shop by Brand, Shop All doesn't need to be accounted for
        position: index + (isShopByBrand ? 1 : 2)
      }]
    }, menuItem.name);
  })));
};