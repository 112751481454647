import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14, _templateObject15, _templateObject16, _templateObject17, _templateObject18, _templateObject19;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

// @ts-ignore

/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
var defaultOptions = {};
export var EventCardDataFragmentDoc = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    fragment EventCardData on Event {\n  id\n  title\n  badgeText\n  url\n  image {\n    single {\n      url\n      altText\n    }\n  }\n  product {\n    id\n  }\n}\n    "])));
export var ProductCardDataFragmentDoc = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    fragment ProductCardData on Product {\n  __typename\n  id\n  title\n  ratingsSummary {\n    numberOfRatings\n    averageRating\n  }\n  assets {\n    hero {\n      url\n      altText\n    }\n  }\n  brand {\n    name\n    slug\n  }\n  priceTag {\n    priceDiscount {\n      label\n      percentageLabel\n      endDate\n    }\n    topLabel\n    priceLabel {\n      wasPrice {\n        amount\n      }\n      price {\n        amount\n      }\n      saving {\n        label\n        savingAmount {\n          amount\n        }\n      }\n    }\n    clubSellPrice {\n      amount\n    }\n    clubExclusive\n    enabled\n    retailPriceDisclaimer {\n      description\n    }\n  }\n  bestOffer {\n    id\n    clubExclusive\n    clubFreeShipping\n    freeShipping\n    sellPrice {\n      amount\n    }\n    seller {\n      id\n      name\n    }\n    badges\n    salesRuleOffers {\n      id\n      label\n      eventId\n      eventSlug\n      disclaimerTitle\n    }\n    variant {\n      id\n    }\n  }\n  options {\n    name\n  }\n  sellable\n  slug\n  adult\n}\n    "])));
export var SponsoredPriceTagDataFragmentDoc = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    fragment SponsoredPriceTagData on PriceTag {\n  __typename\n  priceDiscount {\n    label\n    percentageLabel\n    endDate\n  }\n  topLabel\n  priceLabel {\n    wasPrice {\n      amount\n    }\n    price {\n      amount\n    }\n    saving {\n      label\n      savingAmount {\n        amount\n      }\n    }\n  }\n  clubSellPrice {\n    amount\n  }\n  clubExclusive\n  enabled\n  retailPriceDisclaimer {\n    description\n  }\n}\n    "])));
export var SponsoredProductCardDataFragmentDoc = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    fragment SponsoredProductCardData on Product {\n  __typename\n  id\n  title\n  ratingsSummary {\n    numberOfRatings\n    averageRating\n  }\n  assets {\n    hero {\n      url\n      altText\n    }\n  }\n  brand {\n    name\n    slug\n  }\n  priceTag {\n    ...SponsoredPriceTagData\n  }\n  selectedOffer {\n    id\n    clubExclusive\n    clubFreeShipping\n    freeShipping\n    sellPrice {\n      amount\n    }\n    seller {\n      id\n      name\n    }\n    badges\n    priceTag {\n      ...SponsoredPriceTagData\n    }\n  }\n  options {\n    name\n  }\n  sellable\n  slug\n  adult\n  isSponsored\n}\n    ", ""])), SponsoredPriceTagDataFragmentDoc);
export var BestSellerProductsDocument = gql(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n    query BestSellerProducts($categoryId: Int, $limit: Int!, $offset: Int!) {\n  bestSellerProducts(\n    input: {categoryId: $categoryId, limit: $limit, offset: $offset}\n  ) {\n    products {\n      ...ProductCardData\n    }\n    metaData {\n      preHydrationCount\n      postHydrationCount\n      hydrationErrorCount\n    }\n    pagination {\n      totalCount\n      nextItemIndex\n    }\n  }\n}\n    ", ""])), ProductCardDataFragmentDoc);
/**
 * __useBestSellerProductsQuery__
 *
 * To run a query within a React component, call `useBestSellerProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBestSellerProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBestSellerProductsQuery({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */

export function useBestSellerProductsQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(BestSellerProductsDocument, options);
}
export function useBestSellerProductsLazyQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(BestSellerProductsDocument, options);
}
export function useBestSellerProductsSuspenseQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(BestSellerProductsDocument, options);
}
export var BrandRecommendedProductsDocument = gql(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n    query BrandRecommendedProducts($productIds: [ID!]!, $limit: Int!, $offset: Int!) {\n  brandRecommendedProducts(\n    input: {productIds: $productIds, limit: $limit, offset: $offset}\n  ) {\n    products {\n      ...ProductCardData\n    }\n    metaData {\n      preHydrationCount\n      postHydrationCount\n      hydrationErrorCount\n    }\n    pagination {\n      totalCount\n      nextItemIndex\n    }\n  }\n}\n    ", ""])), ProductCardDataFragmentDoc);
/**
 * __useBrandRecommendedProductsQuery__
 *
 * To run a query within a React component, call `useBrandRecommendedProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandRecommendedProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandRecommendedProductsQuery({
 *   variables: {
 *      productIds: // value for 'productIds'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */

export function useBrandRecommendedProductsQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(BrandRecommendedProductsDocument, options);
}
export function useBrandRecommendedProductsLazyQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(BrandRecommendedProductsDocument, options);
}
export function useBrandRecommendedProductsSuspenseQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(BrandRecommendedProductsDocument, options);
}
export var CoPurchasedProductsDocument = gql(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n    query CoPurchasedProducts($productId: ID!, $adult: Boolean, $includeClubProducts: Boolean) {\n  coPurchasedProducts(\n    input: {productId: $productId, includeAdultCategory: $adult, includeClubProducts: $includeClubProducts}\n  ) {\n    products {\n      id\n      title\n      assets {\n        hero {\n          url\n          altText\n        }\n      }\n      bestOffer {\n        id\n        sellPrice {\n          amount\n        }\n        purchasableQuantity\n      }\n    }\n    metaData {\n      preHydrationCount\n      postHydrationCount\n      hydrationErrorCount\n    }\n  }\n}\n    "])));
/**
 * __useCoPurchasedProductsQuery__
 *
 * To run a query within a React component, call `useCoPurchasedProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoPurchasedProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoPurchasedProductsQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *      adult: // value for 'adult'
 *      includeClubProducts: // value for 'includeClubProducts'
 *   },
 * });
 */

export function useCoPurchasedProductsQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(CoPurchasedProductsDocument, options);
}
export function useCoPurchasedProductsLazyQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(CoPurchasedProductsDocument, options);
}
export function useCoPurchasedProductsSuspenseQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(CoPurchasedProductsDocument, options);
}
export var ConstructorRecommenderDocument = gql(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n    query ConstructorRecommender($podId: ID!, $limit: Int, $term: String, $filters: [ConstructorRecommenderFilters!], $customerId: String) {\n  constructorRecommender(\n    input: {podId: $podId, limit: $limit, term: $term, filters: $filters, customerId: $customerId}\n  ) {\n    products {\n      ...ProductCardData\n    }\n  }\n}\n    ", ""])), ProductCardDataFragmentDoc);
/**
 * __useConstructorRecommenderQuery__
 *
 * To run a query within a React component, call `useConstructorRecommenderQuery` and pass it any options that fit your needs.
 * When your component renders, `useConstructorRecommenderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConstructorRecommenderQuery({
 *   variables: {
 *      podId: // value for 'podId'
 *      limit: // value for 'limit'
 *      term: // value for 'term'
 *      filters: // value for 'filters'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */

export function useConstructorRecommenderQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(ConstructorRecommenderDocument, options);
}
export function useConstructorRecommenderLazyQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(ConstructorRecommenderDocument, options);
}
export function useConstructorRecommenderSuspenseQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(ConstructorRecommenderDocument, options);
}
export var SellerRecommendedProductsDocument = gql(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n    query SellerRecommendedProducts($productId: ID, $excludedProductIds: [ID!], $sellerId: Int, $includeAdultCategory: Boolean, $limit: Int!, $offset: Int!) {\n  sellerRecommendedProducts(\n    input: {productId: $productId, excludedProductIds: $excludedProductIds, sellerId: $sellerId, includeAdultCategory: $includeAdultCategory, limit: $limit, offset: $offset}\n  ) {\n    products {\n      ...ProductCardData\n    }\n    metaData {\n      preHydrationCount\n      postHydrationCount\n      hydrationErrorCount\n    }\n    pagination {\n      totalCount\n      nextItemIndex\n    }\n  }\n}\n    ", ""])), ProductCardDataFragmentDoc);
/**
 * __useSellerRecommendedProductsQuery__
 *
 * To run a query within a React component, call `useSellerRecommendedProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSellerRecommendedProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSellerRecommendedProductsQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *      excludedProductIds: // value for 'excludedProductIds'
 *      sellerId: // value for 'sellerId'
 *      includeAdultCategory: // value for 'includeAdultCategory'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */

export function useSellerRecommendedProductsQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(SellerRecommendedProductsDocument, options);
}
export function useSellerRecommendedProductsLazyQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(SellerRecommendedProductsDocument, options);
}
export function useSellerRecommendedProductsSuspenseQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(SellerRecommendedProductsDocument, options);
}
export var OneRecommenderDocument = gql(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n    query OneRecommender($productId: ID!, $recentlyViewedProductIds: [ID!], $limit: Int!, $offset: Int, $sessionId: String, $sponsoredProductsInput: RetailMediaProductsInput, $includeAdultCategory: Boolean) {\n  oneRecommender(\n    input: {productId: $productId, recentlyViewedProductIds: $recentlyViewedProductIds, limit: $limit, offset: $offset, sessionId: $sessionId, sponsoredProductsInput: $sponsoredProductsInput, includeAdultCategory: $includeAdultCategory}\n  ) {\n    products {\n      ...SponsoredProductCardData\n    }\n    pagination {\n      totalCount\n      nextItemIndex\n    }\n    metaData {\n      sponsoredProductsTracking {\n        id\n        clickTrackingUrl\n        impressionTrackingUrl\n        productId\n      }\n      sponsoredRequestId\n    }\n  }\n}\n    ", ""])), SponsoredProductCardDataFragmentDoc);
/**
 * __useOneRecommenderQuery__
 *
 * To run a query within a React component, call `useOneRecommenderQuery` and pass it any options that fit your needs.
 * When your component renders, `useOneRecommenderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOneRecommenderQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *      recentlyViewedProductIds: // value for 'recentlyViewedProductIds'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sessionId: // value for 'sessionId'
 *      sponsoredProductsInput: // value for 'sponsoredProductsInput'
 *      includeAdultCategory: // value for 'includeAdultCategory'
 *   },
 * });
 */

export function useOneRecommenderQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(OneRecommenderDocument, options);
}
export function useOneRecommenderLazyQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(OneRecommenderDocument, options);
}
export function useOneRecommenderSuspenseQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(OneRecommenderDocument, options);
}
export var PeopleAlsoViewedDocument = gql(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n    query PeopleAlsoViewed($productIds: [ID!]!, $limit: Int!, $offset: Int!, $adult: Boolean!, $incompatibleItemsAction: IncompatibleItemsAction) {\n  peopleAlsoViewed(\n    input: {productIds: $productIds, limit: $limit, offset: $offset, includeAdultCategory: $adult, incompatibleItemsAction: $incompatibleItemsAction}\n  ) {\n    products {\n      ...ProductCardData\n    }\n    metaData {\n      preHydrationCount\n      postHydrationCount\n      hydrationErrorCount\n    }\n    pagination {\n      totalCount\n      nextItemIndex\n    }\n  }\n}\n    ", ""])), ProductCardDataFragmentDoc);
/**
 * __usePeopleAlsoViewedQuery__
 *
 * To run a query within a React component, call `usePeopleAlsoViewedQuery` and pass it any options that fit your needs.
 * When your component renders, `usePeopleAlsoViewedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePeopleAlsoViewedQuery({
 *   variables: {
 *      productIds: // value for 'productIds'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      adult: // value for 'adult'
 *      incompatibleItemsAction: // value for 'incompatibleItemsAction'
 *   },
 * });
 */

export function usePeopleAlsoViewedQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(PeopleAlsoViewedDocument, options);
}
export function usePeopleAlsoViewedLazyQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(PeopleAlsoViewedDocument, options);
}
export function usePeopleAlsoViewedSuspenseQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(PeopleAlsoViewedDocument, options);
}
export var PersonalisedProductRecommendationsDocument = gql(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n    query PersonalisedProductRecommendations($limit: Int!, $adult: Boolean, $offset: Int!) {\n  personalisedRecommendedProducts(\n    input: {limit: $limit, offset: $offset, includeAdultCategory: $adult}\n  ) {\n    products {\n      ...ProductCardData\n    }\n    metaData {\n      preHydrationCount\n      postHydrationCount\n      hydrationErrorCount\n    }\n    pagination {\n      totalCount\n      nextItemIndex\n    }\n  }\n}\n    ", ""])), ProductCardDataFragmentDoc);
/**
 * __usePersonalisedProductRecommendationsQuery__
 *
 * To run a query within a React component, call `usePersonalisedProductRecommendationsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonalisedProductRecommendationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonalisedProductRecommendationsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      adult: // value for 'adult'
 *      offset: // value for 'offset'
 *   },
 * });
 */

export function usePersonalisedProductRecommendationsQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(PersonalisedProductRecommendationsDocument, options);
}
export function usePersonalisedProductRecommendationsLazyQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(PersonalisedProductRecommendationsDocument, options);
}
export function usePersonalisedProductRecommendationsSuspenseQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(PersonalisedProductRecommendationsDocument, options);
}
export var RecentlyViewedProductsDocument = gql(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n    query RecentlyViewedProducts($limit: Int!, $maxDays: Int, $offset: Int!, $excludePurchased: Boolean) {\n  recentlyViewed(\n    input: {limit: $limit, maximumDaysSinceViewed: $maxDays, offset: $offset, excludePurchasedProducts: $excludePurchased}\n  ) {\n    products {\n      ...ProductCardData\n    }\n    metaData {\n      preHydrationCount\n      postHydrationCount\n      hydrationErrorCount\n    }\n    pagination {\n      totalCount\n      nextItemIndex\n    }\n  }\n}\n    ", ""])), ProductCardDataFragmentDoc);
/**
 * __useRecentlyViewedProductsQuery__
 *
 * To run a query within a React component, call `useRecentlyViewedProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecentlyViewedProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecentlyViewedProductsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      maxDays: // value for 'maxDays'
 *      offset: // value for 'offset'
 *      excludePurchased: // value for 'excludePurchased'
 *   },
 * });
 */

export function useRecentlyViewedProductsQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(RecentlyViewedProductsDocument, options);
}
export function useRecentlyViewedProductsLazyQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(RecentlyViewedProductsDocument, options);
}
export function useRecentlyViewedProductsSuspenseQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(RecentlyViewedProductsDocument, options);
}
export var RecommendedEventsDocument = gql(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["\n    query recommendedEvents($productIds: [ID!]!, $limit: Int!) {\n  recommendedEvents(input: {productIds: $productIds, limit: $limit}) {\n    events {\n      ...EventCardData\n    }\n    metaData {\n      preHydrationCount\n      postHydrationCount\n      hydrationErrorCount\n    }\n  }\n}\n    ", ""])), EventCardDataFragmentDoc);
/**
 * __useRecommendedEventsQuery__
 *
 * To run a query within a React component, call `useRecommendedEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecommendedEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecommendedEventsQuery({
 *   variables: {
 *      productIds: // value for 'productIds'
 *      limit: // value for 'limit'
 *   },
 * });
 */

export function useRecommendedEventsQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(RecommendedEventsDocument, options);
}
export function useRecommendedEventsLazyQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(RecommendedEventsDocument, options);
}
export function useRecommendedEventsSuspenseQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(RecommendedEventsDocument, options);
}
export var RecommendedEventsV2Document = gql(_templateObject15 || (_templateObject15 = _taggedTemplateLiteral(["\n    query recommendedEventsV2($productIds: [ID!]!, $limit: Int!) {\n  recommendedEventsV2(input: {productIds: $productIds, limit: $limit}) {\n    events {\n      ...EventCardData\n    }\n    metaData {\n      preHydrationCount\n      postHydrationCount\n      hydrationErrorCount\n    }\n  }\n}\n    ", ""])), EventCardDataFragmentDoc);
/**
 * __useRecommendedEventsV2Query__
 *
 * To run a query within a React component, call `useRecommendedEventsV2Query` and pass it any options that fit your needs.
 * When your component renders, `useRecommendedEventsV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecommendedEventsV2Query({
 *   variables: {
 *      productIds: // value for 'productIds'
 *      limit: // value for 'limit'
 *   },
 * });
 */

export function useRecommendedEventsV2Query(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(RecommendedEventsV2Document, options);
}
export function useRecommendedEventsV2LazyQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(RecommendedEventsV2Document, options);
}
export function useRecommendedEventsV2SuspenseQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(RecommendedEventsV2Document, options);
}
export var SearchTermRecommendedProductsDocument = gql(_templateObject16 || (_templateObject16 = _taggedTemplateLiteral(["\n    query SearchTermRecommendedProducts($searchTerm: String!, $limit: Int!, $offset: Int!) {\n  searchTermRecommendedProducts(\n    input: {searchTerm: $searchTerm, limit: $limit, offset: $offset}\n  ) {\n    products {\n      ...ProductCardData\n    }\n    pagination {\n      totalCount\n      nextItemIndex\n    }\n  }\n}\n    ", ""])), ProductCardDataFragmentDoc);
/**
 * __useSearchTermRecommendedProductsQuery__
 *
 * To run a query within a React component, call `useSearchTermRecommendedProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchTermRecommendedProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchTermRecommendedProductsQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */

export function useSearchTermRecommendedProductsQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(SearchTermRecommendedProductsDocument, options);
}
export function useSearchTermRecommendedProductsLazyQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(SearchTermRecommendedProductsDocument, options);
}
export function useSearchTermRecommendedProductsSuspenseQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(SearchTermRecommendedProductsDocument, options);
}
export var SimilarProductsDocument = gql(_templateObject17 || (_templateObject17 = _taggedTemplateLiteral(["\n    query SimilarProducts($productId: ID!, $limit: Int!, $adult: Boolean!, $offset: Int!, $modelType: ModelType!) {\n  similarProducts(\n    input: {productId: $productId, limit: $limit, offset: $offset, includeAdultCategory: $adult, modelType: $modelType}\n  ) {\n    products {\n      ...ProductCardData\n    }\n    metaData {\n      preHydrationCount\n      postHydrationCount\n      hydrationErrorCount\n    }\n    pagination {\n      totalCount\n      nextItemIndex\n    }\n  }\n}\n    ", ""])), ProductCardDataFragmentDoc);
/**
 * __useSimilarProductsQuery__
 *
 * To run a query within a React component, call `useSimilarProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimilarProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimilarProductsQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *      limit: // value for 'limit'
 *      adult: // value for 'adult'
 *      offset: // value for 'offset'
 *      modelType: // value for 'modelType'
 *   },
 * });
 */

export function useSimilarProductsQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(SimilarProductsDocument, options);
}
export function useSimilarProductsLazyQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(SimilarProductsDocument, options);
}
export function useSimilarProductsSuspenseQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(SimilarProductsDocument, options);
}
export var SponsoredProductRecommenderDocument = gql(_templateObject18 || (_templateObject18 = _taggedTemplateLiteral(["\n    query SponsoredProductRecommender($inventoryId: String!, $limit: Int, $offerId: String, $category: String, $snowplowSessionId: String, $customerUUID: String, $pageId: String!, $excludeProductIds: [String!], $facets: FacetInput) {\n  retailMediaProducts(\n    input: {inventoryId: $inventoryId, snowplowSessionId: $snowplowSessionId, limit: $limit, customerUUID: $customerUUID, offerId: $offerId, category: $category, pageId: $pageId, excludeProductIds: $excludeProductIds, facets: $facets}\n  ) {\n    products {\n      ...SponsoredProductCardData\n    }\n    tracking {\n      id\n      clickTrackingUrl\n      impressionTrackingUrl\n      productId\n    }\n  }\n}\n    ", ""])), SponsoredProductCardDataFragmentDoc);
/**
 * __useSponsoredProductRecommenderQuery__
 *
 * To run a query within a React component, call `useSponsoredProductRecommenderQuery` and pass it any options that fit your needs.
 * When your component renders, `useSponsoredProductRecommenderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSponsoredProductRecommenderQuery({
 *   variables: {
 *      inventoryId: // value for 'inventoryId'
 *      limit: // value for 'limit'
 *      offerId: // value for 'offerId'
 *      category: // value for 'category'
 *      snowplowSessionId: // value for 'snowplowSessionId'
 *      customerUUID: // value for 'customerUUID'
 *      pageId: // value for 'pageId'
 *      excludeProductIds: // value for 'excludeProductIds'
 *      facets: // value for 'facets'
 *   },
 * });
 */

export function useSponsoredProductRecommenderQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(SponsoredProductRecommenderDocument, options);
}
export function useSponsoredProductRecommenderLazyQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(SponsoredProductRecommenderDocument, options);
}
export function useSponsoredProductRecommenderSuspenseQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(SponsoredProductRecommenderDocument, options);
}
export var TrendingProductsDocument = gql(_templateObject19 || (_templateObject19 = _taggedTemplateLiteral(["\n    query TrendingProducts($category: String, $limit: Int!, $offset: Int!) {\n  trendingProducts(input: {category: $category, limit: $limit, offset: $offset}) {\n    products {\n      ...ProductCardData\n    }\n    metaData {\n      preHydrationCount\n      postHydrationCount\n      hydrationErrorCount\n    }\n    pagination {\n      totalCount\n      nextItemIndex\n    }\n  }\n}\n    ", ""])), ProductCardDataFragmentDoc);
/**
 * __useTrendingProductsQuery__
 *
 * To run a query within a React component, call `useTrendingProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrendingProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrendingProductsQuery({
 *   variables: {
 *      category: // value for 'category'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */

export function useTrendingProductsQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(TrendingProductsDocument, options);
}
export function useTrendingProductsLazyQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(TrendingProductsDocument, options);
}
export function useTrendingProductsSuspenseQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(TrendingProductsDocument, options);
}