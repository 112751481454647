import { emitMenuClick, emitMenuImpression } from "@catchoftheday/analytics";
export function trackMenuClick(_ref) {
  var name = _ref.name,
      url = _ref.url,
      item_path = _ref.item_path,
      source_type = _ref.source_type;
  emitMenuClick({
    name: name,
    item_path: item_path,
    url: url,
    source_type: source_type
  });
}
export function trackMenuImpression(_ref2) {
  var name = _ref2.name,
      item_path = _ref2.item_path,
      source_type = _ref2.source_type;
  emitMenuImpression({
    name: name,
    item_path: item_path,
    source_type: source_type
  });
}