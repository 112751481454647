import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React from "react";
import { emitRecommenderRequest } from "@catchoftheday/analytics";
import { useConstructorRecommenderQuery } from "@personalisation/generated/graphql";
import { RecommenderCarousel } from "../RecommenderCarousel";
import { getProductTiles, getRecommenderTrackingData } from "../utils";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ConstructorRecommender = function ConstructorRecommender(_ref) {
  var podId = _ref.podId,
      limit = _ref.limit,
      term = _ref.term,
      filters = _ref.filters,
      customerId = _ref.customerId,
      sourceId = _ref.sourceId,
      sourceType = _ref.sourceType,
      heading = _ref.heading,
      wrapperProps = _ref.wrapperProps,
      _ref$showBullets = _ref.showBullets,
      showBullets = _ref$showBullets === void 0 ? false : _ref$showBullets;
  var fromProduct = {
    from_product_ids: null,
    from_product_offer_id: null,
    from_product_displayed_price: null
  };

  var _useConstructorRecomm = useConstructorRecommenderQuery({
    variables: {
      podId: podId,
      limit: limit,
      term: term,
      filters: filters,
      customerId: customerId
    },
    ssr: false,
    onCompleted: function onCompleted() {
      emitRecommenderRequest(_objectSpread({
        source_type: sourceType
      }, fromProduct));
    }
  }),
      data = _useConstructorRecomm.data,
      error = _useConstructorRecomm.error,
      loading = _useConstructorRecomm.loading;

  if (!(data !== null && data !== void 0 && data.constructorRecommender.products.length) || error) {
    return null;
  }

  var products = data.constructorRecommender.products;
  var tiles = getProductTiles({
    products: products,
    sourceType: sourceType,
    sourceId: sourceId,
    fromProduct: fromProduct
  });
  var trackingData = getRecommenderTrackingData(products, "product");
  return ___EmotionJSX(RecommenderCarousel, {
    tiles: tiles,
    heading: heading || "Popular Products",
    sourceType: sourceType,
    sourceId: sourceId,
    trackingData: trackingData,
    fromProduct: fromProduct,
    carouselProps: {
      disableNavButtons: {
        next: loading
      },
      touchEnabled: !loading,
      showBullets: showBullets
    },
    wrapperProps: wrapperProps
  });
};