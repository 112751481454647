import React from "react";
import { SOURCE_TYPE_DEPARTMENT_SPONSORED_RECOMMENDER } from "@catchoftheday/analytics";
import { Box, Flex, Heading } from "@catchoftheday/cg-components";
import { SponsoredProductRecommender } from "@catchoftheday/personalisation";
import { SponsoredLabel } from "@catchoftheday/product-components";
import { RecommenderZone } from "../../../layouts/department/PageLayout";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var SponsoredRecommender = function SponsoredRecommender(_ref) {
  var categorySlug = _ref.categorySlug;
  return ___EmotionJSX(Box, null, ___EmotionJSX(RecommenderZone, null, ___EmotionJSX(SponsoredProductRecommender, {
    fromProduct: {
      from_product_ids: null,
      from_product_offer_id: null,
      from_product_displayed_price: null
    },
    inventoryId: "department_page_recommender",
    categorySlug: categorySlug,
    pageId: "category",
    wrapperProps: {
      px: {
        xs: "0px",
        md: "40px"
      }
    },
    sourceType: SOURCE_TYPE_DEPARTMENT_SPONSORED_RECOMMENDER,
    showBullets: true,
    heading: ___EmotionJSX(Flex, {
      alignItems: "center",
      mb: 4,
      px: {
        xs: "12px",
        md: "0"
      },
      justifyContent: "space-between"
    }, ___EmotionJSX(Heading.h2, {
      my: 0
    }, "Recommended products"), ___EmotionJSX(SponsoredLabel, {
      display: "block"
    }))
  })));
};