import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import React from "react";
import { SOURCE_TYPE_DEPARTMENT_TOP_BRANDS } from "@catchoftheday/analytics";
import { Box, Heading, Image as CGImage, NextLink } from "@catchoftheday/cg-components";
import { styled } from "@catchoftheday/theme";
import { TopBrandsZone } from "../../../layouts/department/PageLayout";
import { jsx as ___EmotionJSX } from "@emotion/react";
var sourceType = SOURCE_TYPE_DEPARTMENT_TOP_BRANDS;
var Image = styled(CGImage)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  filter: grayscale(100%);\n  mix-blend-mode: multiply;\n  opacity: 0.7;\n  transition: filter 0.3s ease, opacity 0.3s ease;\n  &:hover {\n    filter: grayscale(0%);\n    opacity: 1;\n  }\n"])));
export var TopBrands = function TopBrands(_ref) {
  var departmentName = _ref.departmentName,
      brands = _ref.brands;
  var topEightBrands = brands.filter(function (brand) {
    return !!brand.image;
  }).slice(0, 8);
  return ___EmotionJSX(Box, null, ___EmotionJSX(Heading.h2, {
    mt: 0,
    mb: 4
  }, "Top Brands in ", departmentName), ___EmotionJSX(TopBrandsZone, null, topEightBrands.map(function (_ref2) {
    var id = _ref2.id,
        slug = _ref2.slug,
        image = _ref2.image;
    return ___EmotionJSX(NextLink, {
      key: id,
      href: "/brand/".concat(slug, "?st=").concat(sourceType)
    }, ___EmotionJSX(Image, {
      src: image.url,
      alt: image.altText
    }));
  })));
};