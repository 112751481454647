import _slicedToArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import React, { useContext, useEffect, useState } from "react";
import { AppExperimentContext, SOURCE_TYPE_CONSTRUCTOR_SEARCH, SOURCE_TYPE_CONSTRUCTOR_SEARCH_RECENTLY_VIEWED, SOURCE_TYPE_CONSTRUCTOR_SEARCH_TRENDING_PRODUCTS } from "@catchoftheday/analytics";
import { mapSearchBarProducts } from "../../../../utilities/searchBar/mapSearchBarProducts";
import { updateRecentSearches } from "../../../../utilities/searchBar/updateRecentSearches";
import { SearchBarHeader, SuggestedProductsZone } from "../../../layouts/SearchBar/SearchBarLayouts";
import { SearchProductCards } from "../../../pure/SearchBar/SearchProductCards/SearchProductCards";
import { ViewAllLink } from "../../../pure/SearchBar/ViewAllLink/ViewAllLink";
import { jsx as ___EmotionJSX } from "@emotion/react";
var sourceTypeMap = {
  "recently-viewed-products": SOURCE_TYPE_CONSTRUCTOR_SEARCH_RECENTLY_VIEWED,
  best_sellers_products: SOURCE_TYPE_CONSTRUCTOR_SEARCH_TRENDING_PRODUCTS
};
var titleMap = {
  recently_viewed_items: "Recently Viewed",
  bestsellers: "Trending Products"
};
export var SearchProductsContainer = function SearchProductsContainer(_ref) {
  var recommendedProducts = _ref.recommendedProducts,
      searchTerm = _ref.searchTerm,
      adultConsent = _ref.adultConsent;

  var _useContext = useContext(AppExperimentContext),
      getExperiments = _useContext.getExperiments;

  var _getExperiments = getExperiments(["search_suggestion_price_tag_visibility_dec_24"]),
      _getExperiments$exper = _slicedToArray(_getExperiments.experiments, 1),
      searchSuggestionPriceVisibility = _getExperiments$exper[0],
      experimentsLoading = _getExperiments.loading;

  var hidePriceOnSearchSuggestions = !experimentsLoading && (searchSuggestionPriceVisibility === null || searchSuggestionPriceVisibility === void 0 ? void 0 : searchSuggestionPriceVisibility.bucket) === 1;

  var _useState = useState([]),
      mappedProducts = _useState[0],
      setMappedProducts = _useState[1];

  var _useState2 = useState(),
      heading = _useState2[0],
      setHeading = _useState2[1];

  var _useState3 = useState(false),
      showViewAllLink = _useState3[0],
      setShowViewAllLink = _useState3[1];

  useEffect(function () {
    if (recommendedProducts !== null && recommendedProducts !== void 0 && recommendedProducts.length) {
      var getByPodId = function getByPodId(id) {
        return recommendedProducts.find(function (section) {
          return "podId" in section && section.podId === id;
        });
      };

      var setProductData = function setProductData(product, showViewAllLink, sourceType) {
        var _product$data, _product$data$, _product$data$$strate;

        var strategyId = (_product$data = product.data) === null || _product$data === void 0 ? void 0 : (_product$data$ = _product$data[0]) === null || _product$data$ === void 0 ? void 0 : (_product$data$$strate = _product$data$.strategy) === null || _product$data$$strate === void 0 ? void 0 : _product$data$$strate.id;
        setMappedProducts(mapSearchBarProducts(product.data, sourceType));
        setHeading(strategyId && strategyId in titleMap ? titleMap[strategyId] : product.displayName);
        setShowViewAllLink(showViewAllLink);
      };

      var podIds = ["recently-viewed-products", "best_sellers_products"];

      for (var _i = 0, _podIds = podIds; _i < _podIds.length; _i++) {
        var podId = _podIds[_i];
        var product = getByPodId(podId);

        if (product !== null && product !== void 0 && product.data.length) {
          setProductData(product, false, sourceTypeMap[podId]);
          return;
        }
      } // If no recently viewed or trending products, we can assume it's a product suggestion


      if (recommendedProducts[0].data.length) {
        setProductData(recommendedProducts[0], true, SOURCE_TYPE_CONSTRUCTOR_SEARCH);
        setHeading("Product Suggestions");
      } else {
        setShowViewAllLink(false);
      }
    }
  }, [recommendedProducts]);
  return ___EmotionJSX(SuggestedProductsZone, null, ___EmotionJSX(SearchBarHeader, {
    mb: 4
  }, !mappedProducts.length ? "No product suggestions found." : heading), mappedProducts.length > 0 && ___EmotionJSX(SearchProductCards, {
    mappedProducts: mappedProducts,
    adultConsent: adultConsent,
    hidePriceTag: hidePriceOnSearchSuggestions,
    onClick: function onClick() {
      updateRecentSearches(searchTerm);
    }
  }), showViewAllLink && ___EmotionJSX(ViewAllLink, {
    searchTerm: searchTerm,
    onClick: function onClick() {
      updateRecentSearches(searchTerm);
    }
  }));
};