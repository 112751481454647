import _toConsumableArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2;

import React from "react";
import { Heading, Link, List, ListItem } from "@catchoftheday/cg-components";
import { styled } from "@catchoftheday/theme";
import { trackMenuClick } from "../../utilities/analytics";
import { MenuLink } from "../MenuLink";
import { jsx as ___EmotionJSX } from "@emotion/react";
var CategoryColumn = styled(ListItem)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  break-inside: avoid;\n"])));
var CategoryHeadingLink = styled(Link)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  transition: none;\n\n  &:hover,\n  &:focus,\n  &:active {\n    text-decoration: none;\n  }\n"])));
export var MenuCategory = function MenuCategory(_ref) {
  var _navItem$children;

  var navItem = _ref.navItem,
      itemPathCategory = _ref.itemPathCategory,
      sourceType = _ref.sourceType;
  return ___EmotionJSX(CategoryColumn, {
    mb: "16px"
  }, ___EmotionJSX(Heading.h5, {
    borderBottom: "solid 1px",
    borderBottomColor: "ui.borderColor",
    mt: "0",
    mb: "10px",
    lineHeight: "1.2rem",
    pb: "4px"
  }, ___EmotionJSX(CategoryHeadingLink, {
    tabIndex: -1,
    id: "head-link-".concat(navItem.id),
    href: navItem.url,
    color: "typography.text",
    onClick: function onClick() {
      return trackMenuClick({
        name: navItem.name,
        url: "".concat(location.origin).concat(navItem.url),
        item_path: itemPathCategory,
        source_type: sourceType
      });
    }
  }, navItem.name)), !!((_navItem$children = navItem.children) !== null && _navItem$children !== void 0 && _navItem$children.length) && ___EmotionJSX(List, {
    m: "0"
  }, navItem.children.map(function (shopItem, index) {
    return ___EmotionJSX(MenuLink, {
      key: shopItem.id,
      navItem: shopItem,
      itemPathLink: [].concat(_toConsumableArray(itemPathCategory), [{
        name: shopItem.name,
        id: shopItem.id,
        position: index + 1
      }]),
      sourceType: sourceType
    });
  })));
};