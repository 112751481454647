export var MappedMenuItems;

(function (MappedMenuItems) {
  MappedMenuItems["ADULTS_ONLY"] = "adults-only";
  MappedMenuItems["ONEPASS"] = "onepass";
  MappedMenuItems["TODAYS_DEALS"] = "today-s-deals";
  MappedMenuItems["CLEARANCE"] = "clearance";
  MappedMenuItems["CHEAP_GROCERIES"] = "cheap-groceries";
  MappedMenuItems["GROCERY_LIQUOR"] = "grocery-liquor";
  MappedMenuItems["WOMENS_FASHION"] = "womens-fashion";
  MappedMenuItems["MENS_FASHION"] = "mens-fashion";
  MappedMenuItems["BEAUTY"] = "beauty";
  MappedMenuItems["HOME_KITCHEN"] = "home-kitchen";
  MappedMenuItems["SPORTS_OUTDOOR"] = "sports-outdoor";
  MappedMenuItems["ELECTRONICS"] = "electronics";
  MappedMenuItems["KIDS_CLOTHING_TOYS"] = "kids-clothing-toys";
  MappedMenuItems["PETS"] = "pets";
  MappedMenuItems["TOYS_GAMES"] = "toys-games";
  MappedMenuItems["FURNITURE"] = "furniture";
  MappedMenuItems["APPLIANCES"] = "appliances";
  MappedMenuItems["ENTERTAINMENT"] = "entertainment";
  MappedMenuItems[MappedMenuItems["SHOP_BY_BRAND_ID"] = 99999999] = "SHOP_BY_BRAND_ID";
})(MappedMenuItems || (MappedMenuItems = {}));

export var FORCE_ORDERED_MENU_ITEMS = [MappedMenuItems.ONEPASS];
/** Desktop Menu */

export var SCROLL_INCREMENT = 3;
export var SCROLL_BUFFER = 30;
export var CATEGORY_MENU_DESKTOP_WIDTH = 200;
export var POPOVER_ZINDEX = 101;
export * from "./accountMenuData";
export * from "./bottomNavData";