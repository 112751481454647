import React, { useContext } from "react";
import { SOURCE_TYPE_DEPARTMENT_CONSTRUCTOR_RECOMMENDER } from "@catchoftheday/analytics";
import { Box, Heading } from "@catchoftheday/cg-components";
import { CustomerContext } from "@catchoftheday/customer";
import { ConstructorRecommender as BaseConstructorRecommender } from "@catchoftheday/personalisation";
import { RecommenderZone } from "../../../layouts/department/PageLayout";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ConstructorRecommender = function ConstructorRecommender(_ref) {
  var _customerData$metaDat;

  var departmentName = _ref.departmentName,
      categoryId = _ref.categoryId;

  var _useContext = useContext(CustomerContext),
      customerData = _useContext.data;

  return ___EmotionJSX(Box, null, ___EmotionJSX(RecommenderZone, null, ___EmotionJSX(BaseConstructorRecommender, {
    podId: "best_sellers_products",
    wrapperProps: {
      px: {
        xs: "0px",
        md: "40px"
      }
    },
    sourceType: SOURCE_TYPE_DEPARTMENT_CONSTRUCTOR_RECOMMENDER,
    limit: 30,
    filters: [{
      key: "group_id",
      value: categoryId
    }],
    customerId: customerData === null || customerData === void 0 ? void 0 : (_customerData$metaDat = customerData.metaData) === null || _customerData$metaDat === void 0 ? void 0 : _customerData$metaDat.customerUuid,
    showBullets: true,
    heading: ___EmotionJSX(Heading.h2, {
      mt: 0,
      mx: {
        xs: "12px",
        md: "0"
      }
    }, "Popular ", departmentName, " products")
  })));
};