export var MOCK_HEADER_DATA = {
  meta: {
    salesChannel: {
      code: "COTD"
    }
  },
  topHeader: {
    leftSection: [{
      name: "Catch Mobile",
      url: "/catch-connect",
      icon: "mobile",
      iconSize: null
    }],
    rightSection: [{
      name: "Careers",
      url: "https://www.catch.com.au/careers ",
      icon: null,
      iconSize: null
    }, {
      name: "Gift Cards",
      url: "/gift-card/",
      icon: null,
      iconSize: null
    }, {
      name: "Help",
      url: "https://help.catch.com.au",
      icon: null,
      iconSize: null
    }, {
      name: "Track My Order",
      url: "https://help.catch.com.au",
      icon: null,
      iconSize: null
    }]
  },
  primaryHeader: {
    logo: {
      enabled: true,
      imageSrc: "https://static.devcat.ch/static/catch/images/logo-83d9b21199.svg",
      alt: "Catch - Screaming Good Deals",
      url: "/",
      condensedImageSrc: null
    },
    searchBar: {
      enabled: true
    },
    club: {
      enabled: false,
      clubCatchTileEnabled: true
    },
    clubFlybuys: {
      enabled: true
    },
    flybuys: {
      enabled: true
    },
    cart: {
      enabled: true
    },
    wishlist: {
      enabled: true
    },
    myAccount: {
      enabled: true,
      showFlybuysLink: true
    }
  },
  headerTheme: "christmas",
  megaMenu: {
    navData: {
      shops: [{
        id: 84,
        pinToTop: true,
        slug: "clearance",
        name: "Clearance",
        url: "/shop/clearance",
        shopNavigationGroups: [],
        topBrands: []
      }, {
        id: 146,
        pinToTop: false,
        slug: "beauty",
        name: "Beauty",
        url: "/shop/beauty",
        shopNavigationGroups: [{
          id: 238,
          slug: "makeup",
          name: "Makeup",
          url: "/shop/beauty/makeup",
          shopNavigationItems: [{
            id: 997,
            slug: "face",
            name: "Face",
            url: "/shop/beauty/makeup/face"
          }, {
            id: 998,
            slug: "eyes",
            name: "Eyes",
            url: "/shop/beauty/makeup/eyes"
          }, {
            id: 999,
            slug: "lips",
            name: "Lips",
            url: "/shop/beauty/makeup/lips"
          }, {
            id: 1000,
            slug: "cheeks",
            name: "Cheeks",
            url: "/shop/beauty/makeup/cheeks"
          }, {
            id: 1001,
            slug: "makeup-sets",
            name: "Makeup Sets",
            url: "/shop/beauty/makeup/makeup-sets"
          }, {
            id: 1002,
            slug: "brushes-tools-accessories",
            name: "Brushes, Tools & Accessories",
            url: "/shop/beauty/makeup/brushes-tools-accessories"
          }, {
            id: 1003,
            slug: "cosmetic-organisers-cases",
            name: "Cosmetic Organisers & Cases",
            url: "/shop/beauty/makeup/cosmetic-organisers-cases"
          }]
        }, {
          id: 249,
          slug: "toiletries",
          name: "Toiletries",
          url: "/shop/beauty/toiletries",
          shopNavigationItems: [{
            id: 1044,
            slug: "handwash-sanitisers",
            name: "Handwash & Sanitisers",
            url: "/shop/beauty/toiletries/handwash-sanitisers"
          }, {
            id: 1045,
            slug: "tissues-cotton-wool-toilet-paper",
            name: "Tissues, Cotton Wool & Toilet Paper",
            url: "/shop/beauty/toiletries/tissues-cotton-wool-toilet-paper"
          }, {
            id: 1046,
            slug: "deodorant-body-spray",
            name: "Deodorant & Body Spray",
            url: "/shop/beauty/toiletries/deodorant-body-spray"
          }, {
            id: 1047,
            slug: "feminine-hygiene",
            name: "Feminine Hygiene",
            url: "/shop/beauty/toiletries/feminine-hygiene"
          }, {
            id: 1048,
            slug: "hair-removal",
            name: "Hair Removal",
            url: "/shop/beauty/toiletries/hair-removal"
          }]
        }, {
          id: 251,
          slug: "manicure-pedicure",
          name: "Manicure & Pedicure",
          url: "/shop/beauty/manicure-pedicure",
          shopNavigationItems: [{
            id: 1055,
            slug: "gel-nail-polish",
            name: "Gel Nail Polish",
            url: "/shop/beauty/manicure-pedicure/gel-nail-polish"
          }, {
            id: 1056,
            slug: "nail-design",
            name: "Nail Design",
            url: "/shop/beauty/manicure-pedicure/nail-design"
          }, {
            id: 1057,
            slug: "nail-polish",
            name: "Nail Polish",
            url: "/shop/beauty/manicure-pedicure/nail-polish"
          }, {
            id: 1058,
            slug: "tools-accessories",
            name: "Tools & Accessories",
            url: "/shop/beauty/manicure-pedicure/tools-accessories"
          }, {
            id: 1059,
            slug: "foot-care",
            name: "Foot Care",
            url: "/shop/beauty/manicure-pedicure/foot-care"
          }]
        }, {
          id: 243,
          slug: "skincare",
          name: "Skincare",
          url: "/shop/beauty/skincare",
          shopNavigationItems: [{
            id: 1020,
            slug: "body",
            name: "Body",
            url: "/shop/beauty/skincare/body"
          }, {
            id: 1021,
            slug: "eyes",
            name: "Eyes",
            url: "/shop/beauty/skincare/eyes"
          }, {
            id: 1022,
            slug: "face",
            name: "Face",
            url: "/shop/beauty/skincare/face"
          }, {
            id: 1023,
            slug: "hands-feet",
            name: "Hands & Feet",
            url: "/shop/beauty/skincare/hands-feet"
          }, {
            id: 1024,
            slug: "sun-care-tanning",
            name: "Sun Care & Tanning",
            url: "/shop/beauty/skincare/sun-care-tanning"
          }]
        }, {
          id: 245,
          slug: "beauty-grooming",
          name: "Beauty & Grooming",
          url: "/shop/beauty/beauty-grooming",
          shopNavigationItems: [{
            id: 1027,
            slug: "facial-brush",
            name: "Facial Brush",
            url: "/shop/beauty/beauty-grooming/facial-brush"
          }, {
            id: 1028,
            slug: "facial-sauner",
            name: "Facial Sauna",
            url: "/shop/beauty/beauty-grooming/facial-sauner"
          }, {
            id: 1029,
            slug: "massager-footspa",
            name: "Massager & Footspa",
            url: "/shop/beauty/beauty-grooming/massager-footspa"
          }, {
            id: 1030,
            slug: "shaving-hair-removal",
            name: "Shaving & Hair Removal",
            url: "/shop/beauty/beauty-grooming/shaving-hair-removal"
          }]
        }, {
          id: 250,
          slug: "dental-oral-care",
          name: "Dental & Oral Care",
          url: "/shop/beauty/dental-oral-care",
          shopNavigationItems: [{
            id: 1049,
            slug: "toothbrushes",
            name: "Toothbrushes",
            url: "/shop/beauty/dental-oral-care/toothbrushes"
          }, {
            id: 1050,
            slug: "toothpaste",
            name: "Toothpaste",
            url: "/shop/beauty/dental-oral-care/toothpaste"
          }, {
            id: 1051,
            slug: "teeth-whitening",
            name: "Teeth Whitening",
            url: "/shop/beauty/dental-oral-care/teeth-whitening"
          }, {
            id: 1052,
            slug: "mouthwash-breath-fresheners",
            name: "Mouthwash & Breath Fresheners",
            url: "/shop/beauty/dental-oral-care/mouthwash-breath-fresheners"
          }, {
            id: 1053,
            slug: "dental-floss-picks",
            name: "Dental Floss & Picks",
            url: "/shop/beauty/dental-oral-care/dental-floss-picks"
          }]
        }, {
          id: 240,
          slug: "mens-grooming",
          name: "Men's Grooming",
          url: "/shop/beauty/mens-grooming",
          shopNavigationItems: [{
            id: 1007,
            slug: "fragrances",
            name: "Fragrances",
            url: "/shop/beauty/mens-grooming/fragrances"
          }, {
            id: 1008,
            slug: "hair-care",
            name: "Hair Care",
            url: "/shop/beauty/mens-grooming/hair-care"
          }, {
            id: 1009,
            slug: "skin-care",
            name: "Skin Care",
            url: "/shop/beauty/mens-grooming/skin-care"
          }, {
            id: 1010,
            slug: "shaving",
            name: "Shaving",
            url: "/shop/beauty/mens-grooming/shaving"
          }]
        }, {
          id: 248,
          slug: "hair-care",
          name: "Hair Care",
          url: "/shop/beauty/hair-care",
          shopNavigationItems: [{
            id: 1037,
            slug: "hair-care-products",
            name: "Shampoo & Conditioner",
            url: "/shop/beauty/hair-care/hair-care-products"
          }, {
            id: 1041,
            slug: "styling-products",
            name: "Styling Products",
            url: "/shop/beauty/hair-care/styling-products"
          }, {
            id: 1042,
            slug: "hair-straighteners-curlers",
            name: "Hair Straighteners/Curlers",
            url: "/shop/beauty/hair-care/hair-straighteners-curlers"
          }, {
            id: 1043,
            slug: "hair-dryer",
            name: "Hair Dryer",
            url: "/shop/beauty/hair-care/hair-dryer"
          }, {
            id: 1040,
            slug: "hair-styling-accessories",
            name: "Hair Styling Accessories",
            url: "/shop/beauty/hair-care/hair-styling-accessories"
          }, {
            id: 1039,
            slug: "hair-cutting-tools",
            name: "Hair Cutting Tools",
            url: "/shop/beauty/hair-care/hair-cutting-tools"
          }, {
            id: 1038,
            slug: "hair-colour",
            name: "Hair Colour",
            url: "/shop/beauty/hair-care/hair-colour"
          }]
        }, {
          id: 244,
          slug: "fragrance",
          name: "Fragrance",
          url: "/shop/beauty/fragrance",
          shopNavigationItems: [{
            id: 1025,
            slug: "women",
            name: "Women",
            url: "/shop/beauty/fragrance/women"
          }, {
            id: 1026,
            slug: "men",
            name: "Men",
            url: "/shop/beauty/fragrance/men"
          }]
        }, {
          id: 239,
          slug: "bath-body",
          name: "Bath & Body",
          url: "/shop/beauty/bath-body",
          shopNavigationItems: [{
            id: 1004,
            slug: "body-moisturiser-body-wash",
            name: "Body Moisturiser & Body Wash",
            url: "/shop/beauty/bath-body/body-moisturiser-body-wash"
          }, {
            id: 1005,
            slug: "bathing-accessories",
            name: "Bathing Accessories",
            url: "/shop/beauty/bath-body/bathing-accessories"
          }, {
            id: 1006,
            slug: "hand-care",
            name: "Hand Care",
            url: "/shop/beauty/bath-body/hand-care"
          }]
        }, {
          id: 241,
          slug: "aromatherapy",
          name: "Aromatherapy",
          url: "/shop/beauty/aromatherapy",
          shopNavigationItems: [{
            id: 1011,
            slug: "essential-oils-melts",
            name: "Essential Oils & Melts",
            url: "/shop/beauty/aromatherapy/essential-oils-melts"
          }, {
            id: 1012,
            slug: "massage-oil",
            name: "Massage Oil",
            url: "/shop/beauty/aromatherapy/massage-oil"
          }, {
            id: 1013,
            slug: "room-mist",
            name: "Room Mist",
            url: "/shop/beauty/aromatherapy/room-mist"
          }, {
            id: 1014,
            slug: "air-purifier",
            name: "Air Purifier",
            url: "/shop/beauty/aromatherapy/air-purifier"
          }, {
            id: 1015,
            slug: "humidifier",
            name: "Humidifier",
            url: "/shop/beauty/aromatherapy/humidifier"
          }, {
            id: 1016,
            slug: "candles",
            name: "Candles",
            url: "/shop/beauty/aromatherapy/candles"
          }]
        }],
        topBrands: [{
          id: 7075,
          slug: "nars",
          name: "Nars",
          url: "/brand/nars"
        }, {
          id: 6065,
          slug: "bys",
          name: "BYS",
          url: "/brand/bys"
        }, {
          id: 4784,
          slug: "loreal",
          name: "L'Oreal",
          url: "/brand/loreal"
        }, {
          id: 4335,
          slug: "maybelline",
          name: "Maybelline",
          url: "/brand/maybelline"
        }, {
          id: 3179,
          slug: "revlon",
          name: "Revlon",
          url: "/brand/revlon"
        }, {
          id: 6143,
          slug: "nude-by-nature",
          name: "Nude by Nature",
          url: "/brand/nude-by-nature"
        }, {
          id: 7630,
          slug: "max-factor",
          name: "Max Factor",
          url: "/brand/max-factor"
        }]
      }],
      items: [{
        id: 1,
        slug: "apparel-footwear",
        name: "Apparel & Footwear",
        url: "/category/apparel-footwear",
        children: [{
          id: 2,
          slug: "apparel-footwear/apparel-footwear-boys",
          name: "Apparel & Footwear Boys",
          url: "/category/apparel-footwear/apparel-footwear-boys",
          children: [{
            id: 3,
            slug: "apparel-footwear/boys-apparel",
            name: "Boys Apparel",
            url: "/category/apparel-footwear/boys-apparel"
          }, {
            id: 17,
            slug: "apparel-footwear/apparel-footwear-boys-costumes",
            name: "Apparel & Footwear Boys Costumes",
            url: "/category/apparel-footwear/apparel-footwear-boys-costumes"
          }, {
            id: 20,
            slug: "apparel-footwear/boys-footwear",
            name: "Boys Footwear",
            url: "/category/apparel-footwear/boys-footwear"
          }, {
            id: 32,
            slug: "apparel-footwear/other-apparel-footwear-boys",
            name: "Other Apparel & Footwear Boys",
            url: "/category/apparel-footwear/other-apparel-footwear-boys"
          }]
        }, {
          id: 215,
          slug: "apparel-footwear/apparel-footwear-womens",
          name: "Apparel & Footwear Women's",
          url: "/category/apparel-footwear/apparel-footwear-womens",
          children: [{
            id: 216,
            slug: "apparel-footwear/apparel-footwear-womens-apparel",
            name: "Apparel & Footwear Women's Apparel",
            url: "/category/apparel-footwear/apparel-footwear-womens-apparel"
          }, {
            id: 233,
            slug: "apparel-footwear/womens-compression-apparel",
            name: "Women's Compression Apparel",
            url: "/category/apparel-footwear/womens-compression-apparel"
          }, {
            id: 237,
            slug: "apparel-footwear/womens-costumes",
            name: "Women's Costumes",
            url: "/category/apparel-footwear/womens-costumes"
          }, {
            id: 240,
            slug: "apparel-footwear/apparel-footwear-womens-footwear",
            name: "Apparel & Footwear Women's Footwear",
            url: "/category/apparel-footwear/apparel-footwear-womens-footwear"
          }]
        }],
        topBrands: [{
          id: 2807,
          slug: "skechers",
          name: "Skechers",
          url: "/brand/skechers"
        }, {
          id: 2825,
          slug: "tommy-hilfiger",
          name: "Tommy Hilfiger",
          url: "/brand/tommy-hilfiger"
        }]
      }],
      pinToTopItems: [{
        id: 84,
        slug: "clearance",
        name: "Clearance",
        url: "/shop/clearance"
      }, {
        id: 187,
        slug: "apple",
        name: "Apple",
        url: "/shop/apple"
      }, {
        id: 69,
        slug: "onepass",
        name: "OnePass",
        url: "/shop/onepass"
      }],
      catchEssentialsMenu: {
        giftCards: "/gift-card/",
        catchMobile: "/catch-connect"
      },
      accountMenu: {
        // TODO: (RU) Do we need these mocks?
        // flybuysNotificationCount: 0,
        // unreadMarketplaceMessageCount: 0,
        loggedInMenu: [{
          name: "My Orders",
          url: "/my-account/my-orders"
        }, {
          name: "Personal Details",
          url: "/my-account/details"
        }, {
          name: "Wishlist",
          url: "/my-account/wishlist"
        }, {
          name: "Messages",
          url: "/my-account/messages"
        }, {
          name: "OnePass",
          url: "/my-account/onepass-subscription"
        }, {
          name: "Flybuys",
          url: "/flybuys"
        }, {
          name: "Coupons",
          url: "/my-account/my-coupons"
        }, {
          name: "Addresses",
          url: "/my-account/address-book"
        }, {
          name: "Subscriptions",
          url: "/my-account/newsletter-subscriptions"
        }, {
          name: "Saved Card",
          url: "/my-account/saved-card"
        }, {
          name: "Feedback",
          url: "/my-account/feedback"
        }, {
          name: "Help Centre",
          url: "https://help.catch.com.au/"
        }, {
          name: "Logout",
          url: "/logout"
        }],
        loggedOutMenu: [{
          name: "Login",
          url: "/login"
        }, {
          name: "Forgot Password",
          url: "/reset-password"
        }, {
          name: "Sign Up",
          url: "/register"
        }, {
          name: "Help Centre",
          url: "https://help.catch.com.au/"
        }]
      }
    }
  }
};