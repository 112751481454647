import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import React from "react";
import { Link, ListItem } from "@catchoftheday/cg-components";
import { styled, themeGet } from "@catchoftheday/theme";
import { trackMenuClick } from "../../utilities/analytics";
import { jsx as ___EmotionJSX } from "@emotion/react";
var StyledLink = styled(Link)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  word-wrap: break-word;\n  transition: none;\n\n  &:hover,\n  &:focus,\n  &:active {\n    text-decoration: none;\n  }\n"])), function (props) {
  return themeGet("colors.typography.text")(props);
}, function (props) {
  return themeGet("fontSizes.xs")(props);
}, function (props) {
  return themeGet("fontWeights.light")(props);
});
export var MenuLink = function MenuLink(_ref) {
  var navItem = _ref.navItem,
      itemPathLink = _ref.itemPathLink,
      sourceType = _ref.sourceType;
  return ___EmotionJSX(ListItem, {
    key: navItem.id,
    m: "0",
    pb: "4.5px",
    lineHeight: "1.15rem"
  }, ___EmotionJSX(StyledLink, {
    tabIndex: -1,
    id: "menu-link-".concat(navItem.name, "-").concat(navItem.id),
    href: navItem.url,
    onClick: function onClick() {
      return trackMenuClick({
        name: navItem.name,
        url: "".concat(location.origin).concat(navItem.url),
        item_path: itemPathLink,
        source_type: sourceType
      });
    }
  }, navItem.name));
};