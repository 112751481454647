import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["mappedProducts", "adultConsent", "hidePriceTag"];
import React from "react";
import { Flex } from "@catchoftheday/cg-components";
import { MiniProductCard } from "@catchoftheday/product-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var SearchProductCards = function SearchProductCards(_ref) {
  var mappedProducts = _ref.mappedProducts,
      adultConsent = _ref.adultConsent,
      _ref$hidePriceTag = _ref.hidePriceTag,
      hidePriceTag = _ref$hidePriceTag === void 0 ? false : _ref$hidePriceTag,
      props = _objectWithoutProperties(_ref, _excluded);

  return ___EmotionJSX(Flex, {
    flexWrap: "wrap",
    pb: "40px"
  }, mappedProducts.map(function (product, index) {
    return ___EmotionJSX(MiniProductCard, _extends({
      key: product.id,
      product: product,
      hidePriceTag: hidePriceTag,
      width: {
        xs: "100%",
        md: "50%"
      },
      mb: {
        xs: "20px",
        md: index > 1 ? "0" : "30px"
      },
      display: {
        xs: index > 1 ? "none" : "flex",
        md: "flex"
      },
      "data-cnstrc-item-id": product.id,
      "data-cnstrc-item-name": product.title,
      showAdultBlur: !adultConsent && product.adult
    }, props));
  }));
};