import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _toConsumableArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _excluded = ["onItemClick"];

var _templateObject;

import React, { useContext } from "react";
import { Box, IconOnePass, List } from "@catchoftheday/cg-components";
import { styled, themeGet } from "@catchoftheday/theme";
import { MappedMenuItems } from "../../../../consts";
import { MenuItem } from "../../../MenuItem";
import { MobileMenuContext } from "../../../MobileMenuContext";
import { CatchEssentialsMenu } from "./CatchEssentialsMenu";
import { jsx as ___EmotionJSX } from "@emotion/react";
var OnePassItem = styled(MenuItem)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  margin: 0px;\n  a,\n  a:hover,\n  a:focus {\n    color: white;\n\n    svg {\n      color: white;\n    }\n  }\n\n  li {\n    display: flex;\n    align-items: center;\n    height: 48px;\n    background-color: ", ";\n    padding-left: 15px;\n    margin-bottom: 0px;\n  }\n"])), function (props) {
  return themeGet("colors.onePass.primary")(props);
});
export var CategoriesMenu = function CategoriesMenu(_ref) {
  var onItemClick = _ref.onItemClick,
      props = _objectWithoutProperties(_ref, _excluded);

  var _useContext = useContext(MobileMenuContext),
      _useContext$items = _useContext.items,
      items = _useContext$items === void 0 ? [] : _useContext$items,
      _useContext$pinToTopI = _useContext.pinToTopItems,
      pinToTopItems = _useContext$pinToTopI === void 0 ? [] : _useContext$pinToTopI;

  var menu = [].concat(_toConsumableArray(pinToTopItems), _toConsumableArray(items));
  return ___EmotionJSX(Box, _extends({
    as: "nav",
    overflowY: "scroll"
  }, props), ___EmotionJSX(List, {
    my: "0px"
  }, menu.map(function (menuItem, index) {
    var _menuItem$children;

    var isOnePass = menuItem.slug === MappedMenuItems.ONEPASS;
    var position = index + 1;
    return isOnePass ? ___EmotionJSX(OnePassItem, {
      "aria-label": "OnePass",
      key: "".concat(menuItem.name, "-").concat(position),
      url: menuItem.url,
      itemPath: [{
        name: menuItem.name,
        id: menuItem.id,
        position: position
      }]
    }, ___EmotionJSX(IconOnePass, {
      width: "72px",
      height: "15px"
    })) : ___EmotionJSX(MenuItem, {
      key: "".concat(menuItem.name, "-").concat(position),
      url: menuItem.url,
      hasChildren: !!((_menuItem$children = menuItem.children) !== null && _menuItem$children !== void 0 && _menuItem$children.length),
      itemPath: [{
        name: menuItem.name,
        id: menuItem.id,
        position: position
      }],
      onClick: function onClick() {
        return onItemClick([menuItem.id]);
      }
    }, menuItem.name);
  })), ___EmotionJSX(CatchEssentialsMenu, {
    startIndex: menu.length
  }));
};