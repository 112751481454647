import _slicedToArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import React, { useContext } from "react";
import { AppExperimentContext } from "@catchoftheday/analytics";
import { ArrowLeftIcon, Box, Flex, Hide, Image, Link, Span } from "@catchoftheday/cg-components";
import { styled, themeGet } from "@catchoftheday/theme";
import { hideOnAndroidWebView } from "@catchoftheday/utilities";
import { CouponNotification } from "@header/components/CouponNotification";
import { useHeaderConfigurationQuery } from "@header/generated/graphql";
import { Container } from "../Container";
import { HeaderContentWrapper } from "../Header/HeaderContentWrapper";
import { useHeaderMetaData } from "../Header/metadata";
import { jsx as ___EmotionJSX } from "@emotion/react";
// Overriding for web
var LogoContainer = styled(Box)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  @media (max-width: ", ") and (min-width: ", ") {\n    width: 96px;\n  }\n"])), themeGet("breakpoints.lg"), themeGet("breakpoints.md"));

var ReturnToCart = function ReturnToCart(_ref) {
  var linkProps = _ref.linkProps;
  return ___EmotionJSX(Link, _extends({
    href: "/cart",
    textDecoration: "underline",
    fontSize: "base",
    fontWeight: "semibold",
    color: "white"
  }, linkProps), ___EmotionJSX(Flex, {
    alignItems: "center",
    justifyContent: "center"
  }, ___EmotionJSX(ArrowLeftIcon, null), ___EmotionJSX(Span, {
    fontSize: "inherit",
    fontWeight: "inherit"
  }, "Return to Cart")));
};

var SimpleHeaderInternal = function SimpleHeaderInternal(_ref2) {
  var _ref2$host = _ref2.host,
      host = _ref2$host === void 0 ? "" : _ref2$host,
      stickyRef = _ref2.stickyRef;

  var _useHeaderConfigurati = useHeaderConfigurationQuery({
    variables: {
      domain: host,
      showAdultShopMenu: false
    },
    ssr: false
  }),
      data = _useHeaderConfigurati.data;

  var _useContext = useContext(AppExperimentContext),
      getExperiments = _useContext.getExperiments;

  var _getExperiments = getExperiments(["category_mega_menu_web_nov_24"]),
      _getExperiments$exper = _slicedToArray(_getExperiments.experiments, 1),
      megaMenuExperiment = _getExperiments$exper[0],
      experimentsLoading = _getExperiments.loading;

  var headerConfiguration = data === null || data === void 0 ? void 0 : data.cmsConfiguration.header;

  var _useHeaderMetaData = useHeaderMetaData({
    headerConfiguration: headerConfiguration,
    useCategoryMegaMenu: !experimentsLoading && (megaMenuExperiment === null || megaMenuExperiment === void 0 ? void 0 : megaMenuExperiment.bucket) === 1
  }),
      metaData = _useHeaderMetaData.metaData,
      isLoading = _useHeaderMetaData.isLoading;

  var defaultWidths = ["3.06rem", "100px", "8.09rem", "10.71875rem"];

  var _ref3 = (headerConfiguration === null || headerConfiguration === void 0 ? void 0 : headerConfiguration.primaryHeader) || {},
      logo = _ref3.logo;

  var _ref4 = logo || {},
      logoUrl = _ref4.url,
      logoAlt = _ref4.alt,
      logoImageSrc = _ref4.imageSrc;

  return ___EmotionJSX(HeaderContentWrapper, {
    stickyRef: stickyRef,
    isLoggedIn: metaData === null || metaData === void 0 ? void 0 : metaData.loggedIn,
    topHeaderChildren: ___EmotionJSX(React.Fragment, null, !isLoading && (metaData === null || metaData === void 0 ? void 0 : metaData.loggedIn) && metaData.couponNotificationEnabled && ___EmotionJSX(CouponNotification, {
      displayName: metaData === null || metaData === void 0 ? void 0 : metaData.displayName
    }))
  }, ___EmotionJSX(Container, {
    backgroundColor: "secondary",
    height: {
      sm: "70px",
      md: "95px"
    },
    position: "relative",
    zIndex: 2
  }, ___EmotionJSX(Flex, {
    alignItems: "center",
    justifyContent: "space-between",
    height: "100%"
  }, ___EmotionJSX(Hide, {
    xs: true,
    sm: true
  }, ___EmotionJSX(ReturnToCart, {
    linkProps: {
      hoverColor: "white"
    }
  })), ___EmotionJSX(LogoContainer, {
    margin: "0 auto",
    width: defaultWidths,
    position: "relative"
  }, ___EmotionJSX(Link, {
    href: logoUrl || "/",
    className: "js-dnt",
    "data-tracking": "header-app-logo",
    display: {
      md: "inline-block"
    },
    ml: {
      md: "-60px"
    }
  }, ___EmotionJSX(Image, {
    "data-testid": "catch-logo",
    maxWidth: "160px",
    width: "300",
    height: "132",
    alt: logoAlt || "Catch Logo",
    src: logoImageSrc || "https://s.catch.com.au/static/catch/images/logo-83d9b21199.svg"
  }))))), ___EmotionJSX(Hide, {
    md: true,
    lg: true,
    xl: true
  }, ___EmotionJSX(Flex, {
    backgroundColor: "white",
    justifyContent: "center",
    alignItems: "center",
    height: "44px",
    borderBottom: "solid 1px",
    borderColor: "grey",
    ml: -2
  }, ___EmotionJSX(ReturnToCart, {
    linkProps: {
      color: "secondary"
    }
  }))));
};

export var SimpleHeader = hideOnAndroidWebView(SimpleHeaderInternal);