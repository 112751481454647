import _toConsumableArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2, _templateObject3;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Flex, Heading, Link, List } from "@catchoftheday/cg-components";
import { styled, useTheme } from "@catchoftheday/theme";
import { MenuCategory } from "@header/components/MegaMenu/DesktopNav/MenuCategory";
import { TopBrands } from "@header/components/MegaMenu/DesktopNav/TopBrands";
import { trackMenuClick, trackMenuImpression } from "@header/components/MegaMenu/utilities/analytics";
import { CATEGORY_MENU_DESKTOP_WIDTH } from "../../consts";
import { jsx as ___EmotionJSX } from "@emotion/react";
var NUM_COLUMNS = 5;
var NavPopout = styled(Flex)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  transition: visibility 0s;\n  width: 1120px;\n\n  &::after {\n    content: \"\";\n    position: absolute;\n    top: 0;\n    right: 0;\n    bottom: 0;\n    left: 0;\n    box-shadow: 20px 0px 40px -7px rgb(0 0 0 / 25%);\n    z-index: -1;\n  }\n\n  @media (max-width: 1379px) {\n    width: 1020px;\n  }\n\n  @media (max-width: 1279px) {\n    width: 920px;\n  }\n\n  @media (max-width: 1179px) {\n    width: 820px;\n  }\n\n  // last breakpoint before Tablet View (i.e. 1023px).\n  @media (max-width: 1079px) {\n    width: 720px;\n  }\n"])));
var Column = styled(List)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  columns: 150px;\n  column-count: ", ";\n  column-gap: 28px;\n"])), NUM_COLUMNS);
var ShopAllLink = styled(Link)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  &:hover,\n  &:focus,\n  &:active {\n    text-decoration: none;\n  }\n"])));
export var SubmenuPopover = function SubmenuPopover(_ref) {
  var _item$children, _item$children3, _item$children4;

  var item = _ref.item,
      itemPathShop = _ref.itemPathShop,
      sourceType = _ref.sourceType,
      setParentFocus = _ref.setParentFocus;
  var theme = useTheme();
  var hasSubmenuItems = !!((_item$children = item.children) !== null && _item$children !== void 0 && _item$children.length);

  var _useState = useState(0),
      focusItemIndex = _useState[0],
      setFocusItemIndex = _useState[1];

  var linkIds = useMemo(function () {
    return [];
  }, []);
  useEffect(function () {
    var _item$children2, _item$topBrands;

    linkIds.push("show-all-link");
    (_item$children2 = item.children) === null || _item$children2 === void 0 ? void 0 : _item$children2.forEach(function (subItem) {
      var _subItem$children;

      linkIds.push("head-link-".concat(subItem.id));
      (_subItem$children = subItem.children) === null || _subItem$children === void 0 ? void 0 : _subItem$children.map(function (navItems) {
        linkIds.push("menu-link-".concat(navItems.name, "-").concat(navItems.id));
      });
    });
    (_item$topBrands = item.topBrands) === null || _item$topBrands === void 0 ? void 0 : _item$topBrands.forEach(function (topBrand) {
      linkIds.push("menu-link-".concat(topBrand.name, "-").concat(topBrand.id));
    });
  }, [linkIds, item]);
  var keyDownHandler = useCallback(function (e) {
    var _document$getElementB, _document$getElementB2;

    switch (e.code) {
      case "Home":
        e.stopPropagation();
        e.preventDefault();
        (_document$getElementB = document.getElementById("".concat(linkIds[0]))) === null || _document$getElementB === void 0 ? void 0 : _document$getElementB.focus();
        setFocusItemIndex(0);
        break;

      case "End":
        e.stopPropagation();
        e.preventDefault();
        (_document$getElementB2 = document.getElementById("".concat(linkIds[linkIds.length - 1]))) === null || _document$getElementB2 === void 0 ? void 0 : _document$getElementB2.focus();
        setFocusItemIndex(linkIds.length - 1);
        break;

      case "ArrowRight":
        e.stopPropagation();
        break;

      case "ArrowUp":
        e.stopPropagation();
        e.preventDefault();

        if (focusItemIndex !== 0) {
          var _document$getElementB3;

          (_document$getElementB3 = document.getElementById("".concat(linkIds[focusItemIndex - 1]))) === null || _document$getElementB3 === void 0 ? void 0 : _document$getElementB3.focus();
          setFocusItemIndex(focusItemIndex - 1);
        }

        break;

      case "ArrowDown":
        e.stopPropagation();
        e.preventDefault();

        if (focusItemIndex !== linkIds.length - 1) {
          var _document$getElementB4;

          (_document$getElementB4 = document.getElementById("".concat(linkIds[focusItemIndex + 1]))) === null || _document$getElementB4 === void 0 ? void 0 : _document$getElementB4.focus();
          setFocusItemIndex(focusItemIndex + 1);
        }

        break;

      case "ArrowLeft":
      case "Escape":
        e.stopPropagation();
        setParentFocus();
        break;

      default:
        break;
    }
  }, [linkIds, focusItemIndex, setParentFocus]);

  if (!hasSubmenuItems) {
    return null;
  }

  return ___EmotionJSX(NavPopout, {
    "aria-label": "Category Submenu",
    backgroundColor: "white",
    m: "auto",
    left: "".concat(CATEGORY_MENU_DESKTOP_WIDTH, "px"),
    maxWidth: theme.maxWidths["default"],
    position: "absolute",
    onKeyDown: keyDownHandler,
    onMouseEnter: function onMouseEnter() {
      return trackMenuImpression({
        name: item.name,
        item_path: itemPathShop,
        source_type: sourceType
      });
    }
  }, ___EmotionJSX(Flex, {
    flexDirection: "column",
    px: "30px",
    pt: "15px",
    pb: "30px",
    width: "80%"
  }, ___EmotionJSX(Flex, {
    alignItems: "flex-end"
  }, ___EmotionJSX(Heading.h3, {
    my: "0px",
    pr: "12px"
  }, item.name), ___EmotionJSX(ShopAllLink, {
    tabIndex: -1,
    id: "show-all-link",
    href: item.url,
    fontSize: "xs",
    fontWeight: "400",
    pb: "4px",
    hoverColor: "secondary",
    onClick: function onClick() {
      trackMenuClick({
        name: "Shop All ".concat(item.name),
        url: "".concat(location.origin).concat(item.url),
        item_path: [].concat(_toConsumableArray(itemPathShop), [{
          name: "Shop All ".concat(item.name),
          id: item.id,
          position: 0
        }]),
        source_type: sourceType
      });
    }
  }, "Shop All")), ___EmotionJSX(Flex, {
    flexDirection: "row",
    position: "relative",
    pt: "25px",
    pb: "32px"
  }, ___EmotionJSX(Column, {
    pr: "12px",
    m: "0",
    width: "100%",
    "data-testid": "nav-column"
  }, (_item$children3 = item.children) === null || _item$children3 === void 0 ? void 0 : _item$children3.map(function (navItem, index) {
    return ___EmotionJSX(MenuCategory, {
      key: navItem.id,
      navItem: navItem,
      itemPathCategory: [].concat(_toConsumableArray(itemPathShop), [{
        name: navItem.name,
        id: navItem.id,
        position: index + 1
      }]),
      sourceType: sourceType
    });
  })))), item.topBrands && ___EmotionJSX(TopBrands, {
    topBrands: item.topBrands,
    itemPathShop: itemPathShop,
    brandPosition: (((_item$children4 = item.children) === null || _item$children4 === void 0 ? void 0 : _item$children4.length) || 0) + 1,
    sourceType: sourceType
  }));
};