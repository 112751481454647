var mapShopToCategory = function mapShopToCategory(shop) {
  return {
    id: shop.id,
    name: shop.name,
    url: shop.url,
    slug: shop.slug,
    topBrands: shop.topBrands.map(function (brand) {
      return {
        id: brand.id,
        name: brand.name,
        url: brand.url,
        slug: brand.slug
      };
    }),
    children: shop.shopNavigationGroups.map(function (group) {
      return {
        id: group.id,
        name: group.name,
        url: group.url,
        slug: group.slug,
        children: group.shopNavigationItems.map(function (item) {
          return {
            id: item.id,
            name: item.name,
            slug: item.slug,
            url: item.url
          };
        })
      };
    })
  };
};

export var mapShops = function mapShops() {
  var shops = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var pinToTopItems = [];
  var items = shops.map(function (shop) {
    if (shop.pinToTop) {
      pinToTopItems.push(mapShopToCategory(shop));
      return null;
    }

    return !shop.pinToTop ? mapShopToCategory(shop) : null;
  }).filter(Boolean);
  return {
    items: items,
    pinToTopItems: pinToTopItems
  };
};