import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _excluded = ["departmentSlug", "categoryUrl", "categories"];

var _templateObject;

import React from "react";
import { SOURCE_TYPE_DEPARTMENT_CATEGORY_NAV } from "@catchoftheday/analytics";
import { Box, Flex, Heading, Image, NextLink, Text } from "@catchoftheday/cg-components";
import { styled, themeGet } from "@catchoftheday/theme";
import { jsx as ___EmotionJSX } from "@emotion/react";
var sourceType = SOURCE_TYPE_DEPARTMENT_CATEGORY_NAV;
var CategoryName = styled(Text)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: -webkit-box;\n  -webkit-box-orient: vertical;\n  -webkit-line-clamp: 2;\n  @media (min-width: ", ") {\n    -webkit-line-clamp: 1;\n  }\n"])), function (props) {
  return themeGet("breakpoints.lg")(props);
});
export var CategoryGrid = function CategoryGrid(_ref) {
  var departmentSlug = _ref.departmentSlug,
      categoryUrl = _ref.categoryUrl,
      categories = _ref.categories,
      containerProps = _objectWithoutProperties(_ref, _excluded);

  return ___EmotionJSX(Box, null, ___EmotionJSX(Flex, {
    alignItems: "center",
    mb: 4
  }, ___EmotionJSX(Heading.h2, {
    my: 0
  }, "Categories"), ___EmotionJSX(NextLink, {
    href: categoryUrl,
    ml: 4,
    color: "text",
    textDecoration: "underline"
  }, "Shop All")), ___EmotionJSX(Box, _extends({
    role: "list",
    display: "grid",
    gridTemplateColumns: {
      _: "repeat(3, 1fr)",
      md: "repeat(4, 1fr)",
      lg: "repeat(6, 1fr)"
    },
    columnGap: {
      _: 2,
      md: 4
    },
    rowGap: {
      _: 4,
      md: 6
    },
    justifyContent: "center"
  }, containerProps), categories.map(function (_ref2, idx) {
    var name = _ref2.name,
        slug = _ref2.slug,
        image = _ref2.image;
    return ___EmotionJSX(Box, {
      key: "".concat(slug, "-").concat(idx),
      role: "listitem",
      "aria-label": name
    }, ___EmotionJSX(NextLink, {
      key: "".concat(slug, "-").concat(idx),
      href: "/category/".concat(slug, "?st=").concat(sourceType),
      color: "text",
      hoverColor: "text",
      hoverTextDecoration: "none",
      width: "100%",
      height: "100%"
    }, ___EmotionJSX(Flex, {
      flexDirection: "column",
      height: "100%"
    }, ___EmotionJSX(Box, {
      flex: 1,
      py: {
        _: 2,
        md: 3
      },
      px: {
        _: 4,
        md: 6
      },
      bg: "white",
      borderRadius: "default"
    }, ___EmotionJSX(Image, {
      src: image === null || image === void 0 ? void 0 : image.url,
      alt: image === null || image === void 0 ? void 0 : image.altText,
      maxHeight: "100%"
    })), ___EmotionJSX(CategoryName, {
      mt: 2,
      mb: 0,
      height: {
        _: "40px",
        lg: "20px"
      },
      fontSize: "base",
      fontWeight: "semibold",
      textAlign: "center",
      lineHeight: "1.3",
      overflow: "hidden",
      textOverflow: "ellipsis"
    }, name))));
  })));
};