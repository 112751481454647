import React from "react";
import { Box, Breadcrumbs, Flex, Heading, Image } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var PageHeader = function PageHeader(_ref) {
  var name = _ref.name,
      slug = _ref.slug,
      heading = _ref.heading,
      subHeading = _ref.subHeading,
      themeColor = _ref.themeColor,
      image = _ref.image;
  return ___EmotionJSX(Box, null, ___EmotionJSX(Breadcrumbs, {
    displayHome: true,
    breadcrumbs: [{
      label: name,
      href: "/department/".concat(slug),
      leaf: true
    }],
    listProps: {
      mb: 4
    }
  }), ___EmotionJSX(Box, {
    display: "grid",
    width: "100%",
    borderRadius: "semi",
    backgroundColor: themeColor,
    gridTemplateColumns: {
      md: "2fr 3fr"
    }
  }, ___EmotionJSX(Box, {
    p: {
      xs: 6,
      md: 12
    }
  }, ___EmotionJSX(Heading.h1, {
    mt: 0,
    mb: 4,
    fontSize: "42px",
    fontWeight: "bold"
  }, heading), ___EmotionJSX(Heading.h2, {
    m: 0,
    fontWeight: "light"
  }, subHeading)), ___EmotionJSX(Flex, {
    justifyContent: "center",
    alignItems: "center"
  }, ___EmotionJSX(Image, {
    src: image.url,
    alt: image.altText,
    maxWidth: "600px"
  }))));
};